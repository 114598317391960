export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username password doesnot match."])}
      },
      "zh": {
        "password_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名密码错误"])}
      }
    }
  })
}
